import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import Dashboard from "./components/Promo/Dashboard";
import AssignmentQueue from "./components/Promo/AssignmentQueue";
import CsrApprovals from "./components/Promo/CsrApprovals";
import AllSubmissions from "./components/Promo/AllSubmissions";
import DenyList from "./components/Promo/DenyList";

import { useSettings } from "../AppContext";

function Promo() {
  const { resources } = useSettings();
  
  let promoResources = [];
  
  if (resources) {
	  promoResources = resources.filter(item => item.setting === "showPromotion");
  }
  
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((index) => setSelected(index), []);

  let tabs = [
    {
      id: "Dashboard",
      title: "Dashboard",
      content: "Dashboard",
      page: <Dashboard />,
      panelID: "DashboardPanel",
    },
    {
      id: "AssignmentQueue",
      title: "Assignment Queue",
      content: "Assignment Queue",
      page: <AssignmentQueue />,
      panelID: "AssignmentQueuePanel",
    },
    {
      id: "CsrApprovals",
      title: "CSR Approvals",
      content: "CSR Approvals",
      page: <CsrApprovals />,
      panelID: "CsrApprovalsPanel",
    },
    {
      id: "AllSubmissions",
      title: "All Submissions",
      content: "All Submissions",
      page: <AllSubmissions />,
      panelID: "AllSubmissionsPanel",
    },
    {
      id: "DenyList",
      title: "Deny List",
      content: "Deny List",
      page: <DenyList />,
      panelID: "DenyListPanel",
    }
  ];
  
  if (promoResources && promoResources.length === 1) {
	  promoResources = promoResources[0].resources;
	  
	  if (promoResources && promoResources.length > 0) {
		  promoResources = promoResources.split(",").map(item => item.trim());
		  tabs = tabs.filter(item => promoResources.includes(item.id));
	  } 
  }

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].title} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}

export default Promo;
